import React, { useState } from "react"
import "./../Invoice/credit_card.css"
import { Modal } from "react-bootstrap"

const CreditCard = ({ showCreditCardModal, setStates }) => {
  const [paymentMethod, setPaymentMethod] = useState()

  const updatePaymentMethod = e => {
    setPaymentMethod(e.target.value)
  }

  const handleClose = () => {
    setStates(states => {
      return { ...states, showCreditCardModal: false }
    })
  }

  return (
    <Modal show={showCreditCardModal} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="creditCardModalTitle">
          Payment Method
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>

      <Modal.Body>
        <form id="sc_Frmswift">
          <div className="showMoreOptBox mb-2">
            <select
              className="form-select"
              name="payment_method"
              id="payment_method"
              onChange={updatePaymentMethod}
              value={paymentMethod}
            >
              <option value="CreditCard">Credit Card</option>
              <option value="ACH">ACH e-Check</option>
              {/*                                    <option value="Bitcoin">Bitcoin</option>
                                                                        <option value="Paybyphone">Paybyphone</option> */}
            </select>
          </div>

          <div className="CCcontainer">
            <div className="card-wrapper" data-jp-card-initialized="true">
              <div className="jp-card-container">
                <div className="jp-card jp-card-unknown">
                  <div className="jp-card-front">
                    <div className="jp-card-logo jp-card-elo">
                      <div className="e">e</div>
                      <div className="l">l</div>
                      <div className="o">o</div>
                    </div>
                    <div className="jp-card-logo jp-card-visa">Visa</div>
                    <div className="jp-card-logo jp-card-visaelectron">
                      Visa
                      <div className="elec">Electron</div>
                    </div>
                    <div className="jp-card-logo jp-card-mastercard">Mastercard</div>
                    <div className="jp-card-logo jp-card-maestro">Maestro</div>
                    <div className="jp-card-logo jp-card-amex"></div>
                    <div className="jp-card-logo jp-card-discover">discover</div>
                    <div className="jp-card-logo jp-card-dinersclub"></div>
                    <div className="jp-card-logo jp-card-dankort">
                      <div className="dk">
                        <div className="d"></div>
                        <div className="k"></div>
                      </div>
                    </div>
                    <div className="jp-card-logo jp-card-jcb">
                      <div className="j">J</div>
                      <div className="c">C</div>
                      <div className="b">B</div>
                    </div>
                    <div className="jp-card-lower">
                      <div className="jp-card-shiny"></div>
                      <div className="jp-card-cvc jp-card-display">•••</div>
                      <div className="jp-card-number jp-card-display">•••• •••• •••• ••••</div>
                      <div className="jp-card-name jp-card-display">Full Name</div>
                      <div
                        className="jp-card-expiry jp-card-display"
                        data-before="month/year"
                        data-after="valid thru"
                      >
                        ••/••
                      </div>
                    </div>
                  </div>
                  <div className="jp-card-back">
                    <div className="jp-card-bar"></div>
                    <div className="jp-card-cvc jp-card-display">•••</div>
                    <div className="jp-card-shiny"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container active mt-3">
              <input className="form-control mb-2" placeholder="Full name" type="text" name="name" />
              <input
                className="form-control mb-2 unknown"
                placeholder="Card number"
                type="tel"
                name="number"
              />
              <div className="row">
                <div className="col-lg-6">
                  <input className="form-control mb-2" placeholder="MM/YY" type="tel" name="expiry" />
                </div>
                <div className="col-lg-6">
                  <input className="form-control mb-2" placeholder="CVV" type="number" name="cvc" />
                </div>
              </div>
            </div>
          </div>
          <div className="ACHcontainer display-none">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-container">
                  <div className="row no-gutters">
                    <div className="col-lg-6 col-md-12 pr-0 pr-sm-1">
                      <input
                        className="form-control mb-2"
                        placeholder="Name of bank"
                        type="text"
                        name="ach_bank_name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 pl-0 pl-sm-1">
                      <input
                        className="form-control mb-2"
                        placeholder="Name on account"
                        type="text"
                        name="ach_account_name"
                      />
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-lg-6 col-md-12 pr-0 pr-sm-1">
                      <input
                        className="form-control mb-2"
                        placeholder="Account number"
                        type="text"
                        name="ach_account_no"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 pl-0 pl-sm-1">
                      <input
                        className="form-control mb-2"
                        placeholder="Routing number"
                        type="text"
                        name="ach_routing_no"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <button type="button" onClick={handleClose} className="btn btn-default" data-bs-dismiss="modal">
          <i className="bi bi-x-lg"></i> Close
        </button>
        <button className="btn btn-primary">
          <i className="bi bi-credit-card-2-back"></i> Save Payment Method
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreditCard
