import * as React from "react"
import Invoice from "../../../components/Drive/Invoice/invoice"
import Seo from "../../../components/seo"
import PrivateRoute from "../../../components/privateRoute"
import ReduxWrapper from "../../../redux/reduxWrapper"

const InvoicePage = props => {
  return (
    <React.Fragment>
      <Seo title="Invoice" />
      <PrivateRoute path="/" component={Invoice} fileId={props.fileId} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<InvoicePage {...props} />} />
export default WrappedPage
