import React from "react"

const AgreementSubscription = ({}) => {
  const doNothing = e => {
    e.preventDefault()
  }
  return (
    <div
      className="tab-pane fade"
      id="agree-subscription"
      role="tabpanel"
      aria-labelledby="agree-subscription-tab"
    >
      <table
        id="agreements_subscriptions_table"
        className="table table-striped customCheckbox table-head-color dataTable no-footer"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th width="5%">
              <a tabIndex="0" href="#" className="agreements_subscriptions_table_action table-head-icon">
                <i className="bi bi-gear-fill"></i>
              </a>
            </th>
            <th width="10%" className="d-none d-md-table-cell text-center">
              Renewal Date
            </th>
            <th width="40%">
              Product{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="15%" className="d-none d-md-table-cell">
              Type{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="10%" className="text-end">
              Amount{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="20%" className="d-none d-md-none d-lg-table-cell"></th>
          </tr>
          <tr className="display-none agreements_subscriptions_table_action_container">
            <td colSpan="6" className="text-left">
              <div className="batch-action-container">
                <button className="btn btn-trash">
                  <i className="bi bi-trash-fill"></i> Move to Trash
                </button>
                <button
                  className="btn medium_purple"
                  data-bs-toggle="tooltip"
                  title="Merge Duplicate Records"
                >
                  <i className="bi bi-share-fill flip-horizontal"></i> Merge
                </button>
                <button
                  className="btn btn-success"
                  data-bs-toggle="tooltip"
                  title="Nest related records into a single row to keep things organized."
                >
                  <i className="bi bi-bezier"></i> Nest
                </button>
                <button className="btn btn-default" data-bs-toggle="tooltip" title="Un-Nest">
                  <i className="bi bi-bezier2"></i> Un-Nest
                </button>
                <button className="btn btn-secondary" data-bs-toggle="tooltip" title="Tags">
                  <i className="bi bi-tags-fill"></i> Tags
                </button>
              </div>
            </td>
          </tr>
          <tr className="display-none agreements_subscriptions_table_action_container filter_table_action_container">
            <td colSpan="6" className="text-left">
              <div className="batch-action-container">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="form-inline mb-3">
                      <label className="mb-0 mr-2">By Type</label>
                      <select className="form-select mb-0">
                        <option value="Image">Image</option>
                        <option value="Audio">Audio</option>
                        <option value="Video">Video</option>
                        <option value="Archive">Archive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-8 col-sm-12">
                    <div className="form-inline mb-2">
                      <h4>
                        <i className="bi bi-funnel-fill"></i> Show me
                        <select className="form-select mb-0 mx-2">
                          <option value="Contacts">Contacts</option>
                          <option value="Files_Workrooms">Files & Workrooms</option>
                          <option value="Accounting_Records">Accounting Records</option>
                        </select>
                        that are…
                      </h4>
                    </div>
                    <div className="mb-3">
                      <select className="form-select mb-3">
                        <option value="Tagged_with…">Tagged with…</option>
                        <option value="Selling_Opportunities">Selling Opportunities</option>
                      </select>
                      <button
                        type="button"
                        className="btnAddNew"
                        id="btnAddNew"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        title="Add New"
                      ></button>
                    </div>
                    <div className="mb-3">
                      <label>& Add a Custom Column</label>
                      <input
                        type="text"
                        name="addCustomColumn"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label>& then sort the records by…</label>
                      <input
                        type="text"
                        name="sortRecordsBy"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                    <h4>
                      <i className="bi bi-bullseye"></i> Saved Filtered Views
                    </h4>
                    <ul>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Helpdesk Tasks
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Homebuyer Selling Opps
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Open Client Projects
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          UserSavedFilterLabelHere
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* <tr className="neutral_row">
                <td className="text-center tableColChkDel">
                    <div className="tableColChkDelBox">
                    <input type="checkbox" name="fileid[]" id="AS_ID1" className="css-checkbox" />
                    <label htmlFor="AS_ID1" className="css-checkbox-label"></label>
                    <span className="tableColChkSpan account_status">
                        <i className="bi bi-box-arrow-right"></i>
                    </span>
                    </div>
                </td>
                <td className="text-center d-none d-md-table-cell">06/08/2017</td>
                <td>
                    Electronic Signature Business Package - Monthly From{" "}
                    <a href="#" className="user_search" data-val="Amitabh Bachchan">
                    Amitabh Bachchan
                    </a>{" "}
                    <a
                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                    className="user_link"
                    target="_blank"
                    >
                    <i className="bi bi-person-fill"></i>
                    </a>
                </td>
                <td className="d-none d-md-table-cell">Subscription</td>
                <td className="text-end">75/mo</td>
                <td className="text-end colAction d-none d-lg-block">
                    <a
                    href="#"
                    className="btn btn-colAction btn-chat"
                    data-bs-toggle="tooltip"
                    title="Comments"
                    >
                    <i className="bi bi-chat-fill"></i>
                    <span className="badge badge-danger">0</span>
                    </a>
                    <a
                    href="#"
                    className="btn btn-secondary btn-colAction btn-users"
                    data-bs-toggle="tooltip"
                    title="Attachments"
                    >
                    <i className="bi bi-paperclip"></i>
                    <span className="badge badge-danger">0</span>
                    </a>
                    <a
                    href="#"
                    className="btn btn-trash btn-colAction"
                    data-bs-toggle="tooltip"
                    title="Delete"
                    >
                    <i className="bi bi-trash-fill"></i>
                    </a>
                </td>
                </tr> */}
        </tbody>
      </table>
    </div>
  )
}

export default AgreementSubscription
