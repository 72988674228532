import React, { useEffect, useState, useRef } from "react"

const Tax = ({ subTotal, taxSubtotal, setTaxSubtotal, states, setStates }) => {
  const [label, setLabel] = useState("No Tax")
  const [showInput, setShowInput] = useState(false)

  const taxTypeInputRef = useRef(null)

  const changeType = e => {
    setLabel(e.target.options[e.target.selectedIndex].label)
    setShowInput(e.target.value === "3")
    setStates({ ...states, taxType: e.target.value })
  }

  const updateValue = e => {
    setStates({ ...states, taxRate: parseFloat(e.target.value) })
  }

  const calculateTax = () => {
    try {
      if (states.taxType === "3") {
        const totalTax = subTotal * ((states.taxRate || 0) / 100)
        setTaxSubtotal(totalTax)
        return
      }
      setTaxSubtotal(0)
    } catch (error) {
      console.error("Tax calculation failed:", error)
    }
  }

  useEffect(() => {
    setLabel(taxTypeInputRef.current.options[taxTypeInputRef.current.selectedIndex].label)
    setShowInput(states.taxType === "3")
    calculateTax()
  }, [states.taxRate, states.taxType, subTotal])

  return (
    <tr>
      <td className="tax-option" align="right">
        <h6 className="text-end">{label}</h6>
        <select
          name="invoice-tax-type"
          id="invoice-tax-type"
          className="form-select form-inline d-none"
          onChange={changeType}
          value={states.taxType}
          ref={taxTypeInputRef}
        >
          <option value="1" data-text="No Tax">
            No Tax
          </option>
          <option value="3" data-text="Tax On Everything">
            Tax
          </option>
          <option value="5" data-text="Tax (Included)">
            Tax (Included)
          </option>
        </select>
      </td>
      <td>
        {showInput && (
          <div className="input-append invoice-taxrate-input input-group">
            <input
              className="invoice-taxrate form-control"
              type="number"
              value={states.taxRate || 0}
              onChange={updateValue}
              min={0}
            />
            <span className="input-group-text">%</span>
          </div>
        )}
      </td>
      <td className="text-end taxTotal">${taxSubtotal.toFixed(2)}</td>
    </tr>
  )
}

export default Tax
