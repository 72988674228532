export const InvoiceTypes = {
  INVOICE: "invoice",
  ESTIMATE: "estimate",
  QUOTE: "quote",
  PURCHASE_ORDER: "purchase_order",
  CREDIT_MEMO: "credit_memo",
  RECEIPT: "receipt",
}

export const InvoiceStatuses = {
  DUE: "due",
  PAID_PARTIAL: "paid_partial",
  PAID: "paid",
  CANCELED: "canceled",
  REFUNDED: "refunded",
  UNPAID_OVERDUE: "unpaid_overdue",
  NOT_YET_COMMITTED: "not_yet_committed",
  COMMITTED: "committed",
  PAYMENT_PENDING: "payment_pending",
}

export default { InvoiceTypes, InvoiceStatuses }
