import React, { useEffect, useState } from "react"
import "./accounting.css"
import AgreementSubscription from "./agreementSubscription"
import Projected from "./projected"
import History from "./history"
import AxiosInstance from "../../../../services/axiosInstance"
import AccountingModal from "./accountingModal"
import { getUserCurrency } from "../../../../services/globalHelpers"

const Accounting = ({ states, setStates }) => {
  const [showAccountingModal, setShowAccountingModal] = useState(false),
    [currencySymbol, setCurrencySymbol] = useState("$"),
    [accounting, setAccounting] = useState({
      metaJson: { contacts: [{}] },
      transactionStatus: "paid",
      transactionType: "purchase_expense",
    })

  const doNothing = e => {
    e.preventDefault()
  }

  useEffect(() => {
    initData()
    const currencyData = getUserCurrency()
    if (currencyData) {
      setCurrencySymbol(currencyData.symbol)
    }
  }, [])

  const initData = async () => {
    const accountings = (await getAccountings()) || []

    setStates({
      ...states,
      accountings: {
        paids: accountings.filter(acc => acc.transactionStatus === "paid"),
        projected: accountings.filter(acc => acc.transactionStatus !== "paid"),
      },
    })
  }

  const getAccountings = async () => {
    try {
      const response = await AxiosInstance.get(`/accounting`)
      if (response.status === 200) {
        return response.data.data
      } else {
        console.error("Unexpected status code:", response.status)
        return null
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
      return null
    }
  }

  const openAccountingModal = e => {
    e.preventDefault()
    setShowAccountingModal(true)
  }

  const clickDelete = (e, accounting, accountingType) => {
    e.preventDefault()
    deleteAccounting(accounting, accountingType)
  }

  const deleteAccounting = async (accounting, accountingType) => {
    try {
      const response = await AxiosInstance.delete(`/accounting/${accounting.id}`)
      if (response.status === 200) {
        console.log(accountingType)
        setStates({
          ...states,
          accountings: {
            ...states.accountings,
            [`${accountingType}`]: states.accountings[`${accountingType}`].filter(
              data => data.id !== accounting.id
            ),
          },
        })
      } else {
        console.error("Unexpected status code:", response.status)
        return null
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
      return null
    }
  }

  const editAccounting = (e, toEdit) => {
    setShowAccountingModal(true)
    const { createdAt, updatedAt, agreement, transactionDate, ...data } = toEdit
    const [date, hour] = transactionDate.split("T")
    setAccounting({ ...data, transactionDate: date })
  }

  const showCreditCardModal = e => {
    e.preventDefault()

    setStates({ ...states, showCreditCardModal: true })
  }

  const openCreditCardModal = e => {
    e.preventDefault()

    setStates({ ...states, showCreditCardModal: true })
  }

  return (
    <div className="col-lg-12 mb-3" id="accounting_panel">
      <div className="accordion" id="accountingPanelAccordion">
        <div className="accordion-item panel panel-accounting">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#accounting_container"
              aria-expanded="true"
              aria-controls="accounting_container"
            >
              <span className="panel-heading-icon">{currencySymbol}</span>
              <span className="panel-heading-title">Accounting</span>
            </div>
          </div>
          <div
            id="accounting_container"
            className="accordion-collapse collapse show"
            aria-labelledby="accounting_container"
          >
            <div className="accordion-body">
              <div className="row tabularMenuContainer">
                <div className="col-sm-12">
                  <div className="float-end">
                    <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                      <li className="nav-item">
                        <div className="btn-group btnAddNewDropdown" role="group">
                          <button
                            id="btnGroupDrop1"
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-plus-lg"></i>{" "}
                            <span className="d-none d-md-inline">Add New</span>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <li>
                              <a className="dropdown-item" href="#" onClick={showCreditCardModal}>
                                {currencySymbol} Payment Method
                              </a>
                              {/* <!-- SHOW USER'S CURRENCY SYMBOL HERE --> */}
                            </li>
                            <li>
                              <a className="dropdown-item" href="#" onClick={openAccountingModal}>
                                {currencySymbol} Manual Record
                              </a>
                              {/* <!-- SHOW USER'S CURRENCY SYMBOL HERE --> */}
                            </li>
                            <li>
                              <a className="dropdown-item" href="#newInvoiceModal" data-bs-toggle="modal">
                                <i className="bi bi-list-ul"></i> Create Invoice
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item btnAddNewSubscription" href="#" onClick={doNothing}>
                                <i className="bi bi-repeat"></i> Agreement
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="history-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#history"
                          role="tab"
                          aria-controls="history"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" title="History / Paid">
                            <i className="bi bi-arrow-counterclockwise"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="projected-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#projected"
                          role="tab"
                          aria-controls="projected"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" title="Projected / Upcoming">
                            <i className="bi bi-arrow-clockwise"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="agree-subscription-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#agree-subscription"
                          role="tab"
                          aria-controls="agree-subscription"
                          aria-selected="false"
                        >
                          <span data-bs-toggle="tooltip" title="Agreements & Subscriptions">
                            <i className="bi bi-repeat"></i>
                          </span>
                        </a>
                      </li>
                      <li className="nav-item nav-item-trash" role="presentation">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={doNothing}
                          data-bs-toggle="tooltip"
                          title="Trash"
                        >
                          <i className="bi bi-trash-fill"></i>
                        </a>
                      </li>
                    </ul>

                    {/* <!-- mobile view--> */}
                    <ul
                      className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <div className="btn-group btnAddNewDropdown" role="group">
                          <button
                            id="btnGroupDrop2"
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-plus-lg"></i>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                            <li>
                              <a className="dropdown-item" href="#" onClick={openCreditCardModal}>
                                {currencySymbol} Payment Method
                              </a>
                              {/* <!-- SHOW USER'S CURRENCY SYMBOL HERE --> */}
                            </li>
                            <li>
                              <a className="dropdown-item" href="#" onClick={openAccountingModal}>
                                {currencySymbol} Manual Record
                              </a>
                              {/* <!-- SHOW USER'S CURRENCY SYMBOL HERE --> */}
                            </li>
                            <li>
                              <a className="dropdown-item" href="#newInvoiceModal" data-bs-toggle="modal">
                                <i className="bi bi-list-ul"></i> Create Invoice
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item btnAddNewSubscription" href="#" onClick={doNothing}>
                                <i className="bi bi-repeat"></i> Agreement
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="btn-group btnDriveView" role="group">
                          <button
                            id="btnGroupDrop3"
                            type="button"
                            className="btn btn-default dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-database-fill"></i>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item active"
                                id="history-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#history"
                                role="tab"
                                aria-controls="history"
                                aria-selected="false"
                              >
                                <i className="bi bi-arrow-counterclockwise"></i> History / Paid
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                id="projected-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#projected"
                                role="tab"
                                aria-controls="projected"
                                aria-selected="false"
                              >
                                <i className="bi bi-arrow-clockwise"></i> Projected / Upcoming
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                id="agree-subscription-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#agree-subscription"
                                role="tab"
                                aria-controls="agree-subscription"
                                aria-selected="false"
                              >
                                <i className="bi bi-repeat"></i> Agreements & Subscriptions
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#" onClick={doNothing}>
                                <i className="bi bi-trash-fill"></i> Trash
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content">
                    {/* <!-- Agreements & Subscriptions table --> */}
                    <History
                      data={states.accountings?.paids}
                      states={states}
                      setStates={setStates}
                      clickDelete={clickDelete}
                      editAccounting={editAccounting}
                    />

                    <Projected
                      data={states.accountings?.projected}
                      states={states}
                      setStates={setStates}
                      clickDelete={clickDelete}
                      editAccounting={editAccounting}
                    />

                    <AgreementSubscription />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountingModal
        showModal={showAccountingModal}
        setShowModal={setShowAccountingModal}
        states={states}
        setStates={setStates}
        accounting={accounting}
        setAccounting={setAccounting}
      />
    </div>
  )
}

export default Accounting
