import React, { useState, useEffect } from "react"
import { getUserName } from "../Doc/Service/docHelper"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"
import {
  EMAIL_TYPE,
  SMS_TYPE,
  emailLabel,
  smsLabel,
} from "../../Contacts/Detail/HistoryNote/historyNoteHelper"
import { QRCodeSVG } from "qrcode.react"

const InstallationOptions = ({
  states,
  setStates,
  previewUrl,
  setShowHistory,
  setHistoryType,
  setHistoryLabel,
  setDefaultMessage,
  emails,
  phones,
  setHistoryValue,
  payor,
}) => {
  const [invId, setInvId] = useState("")
  const [showQrCode, setShowQrCode] = useState(false)

  const username = getUserName()

  useEffect(() => {
    setInvId(`${localStorage.getItem("user_id")}I${states.objectId}`)
  }, [states.objectId])

  const doNothing = e => {
    e.preventDefault()
  }

  return (
    <div className="col-lg-12 mb-3" id="installation_panel">
      <div className="accordion" id="installationPanelAccordion">
        <div className="accordion-item panel panel-beige">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#installation_container"
              aria-expanded="true"
              aria-controls="installation_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-megaphone-fill"></i>
              </span>
              <span className="panel-heading-title">Using your invoice {invId}</span>
            </div>
          </div>
          <div
            id="installation_container"
            className="accordion-collapse collapse show"
            aria-labelledby="installation_container"
          >
            <div className="accordion-body">
              <strong>Here is your URL</strong>

              <div className="customURLContainer py-5 text-center">
                <a className="IO_OriginalURL dashedLinked" target="_blank" href={previewUrl}>
                  {previewUrl}
                </a>
                <input type="text" id="hiddenInvoicePreviewURL" defaultValue={previewUrl} />
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <ul className="IO_Action_Btns">
                    <li>
                      <a href="#" onClick={doNothing} className="">
                        <i className="bi bi-person-fill-check"></i> Request Completion
                      </a>
                    </li>

                    {/* <!-- OPEN URL EDITOR GLOBAL --> */}
                    <li>
                      <a href="#Global_URLInjector" onClick={doNothing} data-bs-toggle="modal">
                        <i className="bi bi-graph-up-arrow"></i> Tracking URL
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-12 pt-5 IOShareContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-share-fill"></i> Share
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="row">
                      <div className="col-lg-12 text-center shareAction colAction">
                        <ul>
                          <li className="black-bg">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Generate QR Code</Tooltip>}>
                              <Link
                                to={"#"}
                                onClick={e => {
                                  e.preventDefault()
                                  setShowQrCode(true)
                                }}
                                className="btnGlobalURLQRCode"
                              >
                                <i className="bi bi-qr-code"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>{" "}
                          {payor?.id && (
                            <>
                              <li className="paper-plane-bg">
                                <OverlayTrigger placement="top" overlay={<Tooltip>Email This Page</Tooltip>}>
                                  <Link
                                    to={"#"}
                                    onClick={() => {
                                      setShowHistory(true)
                                      setHistoryType(EMAIL_TYPE)
                                      setHistoryLabel(emailLabel(emails[0]?.email))
                                      setDefaultMessage(previewUrl)
                                      setHistoryValue(emailLabel(emails[0]?.email))
                                    }}
                                    className="btnOpenChatDockEmail"
                                  >
                                    <i className="bi bi-envelope-fill"></i>
                                  </Link>
                                </OverlayTrigger>
                              </li>{" "}
                              <li className="paper-plane-bg">
                                <OverlayTrigger placement="top" overlay={<Tooltip>SMS This Page</Tooltip>}>
                                  <a
                                    href="#"
                                    className="btnOpenChatDockSMS"
                                    onClick={() => {
                                      setShowHistory(true)
                                      setHistoryType(SMS_TYPE)
                                      setDefaultMessage(previewUrl)
                                      setHistoryLabel(smsLabel(phones[0]?.phone))
                                    }}
                                  >
                                    <i className="bi bi-phone-fill" />
                                  </a>
                                </OverlayTrigger>
                              </li>{" "}
                            </>
                          )}
                          <li className="fb-bg">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Share On Facebook</Tooltip>}>
                              <a href={`https://m.me/PAGE-NAME?text=${previewUrl}`} target="_blank">
                                <i className="bi bi-facebook" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="twitter-bg">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Tweet This Page</Tooltip>}>
                              <a
                                href={`https://twitter.com/share?url=${previewUrl}&text=Hello%0A%0AYou%20will%20like%20this%3A%0A`}
                                target="_blank"
                              >
                                <i className="bi bi-twitter-x" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="pinterest-bg">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Share This Page on Pinterest</Tooltip>}
                            >
                              <a
                                href={`https://www.pinterest.com/pin/create/button/?url=${previewUrl}`}
                                target="_blank"
                              >
                                <i className="bi bi-pinterest" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="instgram-bg">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Share This Page on Instagram</Tooltip>}
                            >
                              <a href={`https://ig.me/m/${username}`} target="_blank">
                                <i className="bi bi-instagram" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="thread-bg">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Share This Page on Thread</Tooltip>}
                            >
                              <a href="https://www.threads.net/" target="_blank">
                                <i className="bi bi-threads" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="linkedin-bg">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Share This Page on LinkedIn</Tooltip>}
                            >
                              <a
                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${previewUrl}&title=You%20will%20like%20this&summary=Hello%0A%0AYou%20will%20like%20this%3A%0A%0A`}
                                target="_blank"
                              >
                                <i className="bi bi-linkedin" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="whatapp-bg">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Send link on Whatsapp</Tooltip>}
                            >
                              <a href={`https://wa.me/?text=${previewUrl}`} target="_blank">
                                <i className="bi bi-whatsapp" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="slack-bg">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Send link on Slack</Tooltip>}>
                              <a href={`https://wa.me/?text=${previewUrl}`} target="_blank">
                                <i className="bi bi-slack" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                          <li className="google-bg">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Send an Email</Tooltip>}>
                              <a
                                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${localStorage.getItem(
                                  "user_email"
                                )}&su=SUBJECT&body=${previewUrl}`}
                                target="_blank"
                              >
                                <i className="bi bi-google" />
                              </a>
                            </OverlayTrigger>
                          </li>{" "}
                        </ul>
                      </div>
                    </div>

                    <div className={`qrcode_container text-center mt-3${!showQrCode ? " d-none" : ""}`}>
                      <QRCodeSVG value={previewUrl} size={275} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstallationOptions
