import React, { useEffect, useRef, useState } from "react"

const DueOptions = ({ total, due, setDue, states, setStates }) => {
  const breakMilestoneInput = useRef(null)
  const breakMilestonePaymentDivisionSelect = useRef(null)
  const [showDueOptions, setShowDueOptions] = useState(false)

  const updatePartialPayment = e => {
    setStates({ ...states, allPartialPayment: e.target.checked ? "1" : "0" })
  }

  const breakToMilestonePayment = e => {
    setStates({
      ...states,
      breakMilestonePaymentPlan: e.target.checked ? "1" : "0",
      paymentPlanOptions: e.target.checked ? 2 : 1,
      finalTotal: total / (e.target.checked ? 2 : 1),
    })
    setDue(total / (e.target.checked ? 2 : 1))
  }

  const updateDue = e => {
    setDue(total / e.target.value)
    setStates({ ...states, paymentPlanOptions: e.target.value, finalTotal: total / e.target.value })
  }

  useEffect(() => {
    const division =
      states.breakMilestonePaymentPlan === "0" ? 1 : breakMilestonePaymentDivisionSelect.current?.value || 2
    setDue(total / division)
    setStates({ ...states, finalTotal: total / division })
  }, [total, setDue, states.paymentPlanOptions])

  const updateCommittementStatus = e => {
    setStates({ ...states, committedOrNot: e.target.value })
  }

  return (
    <>
      {showDueOptions && (
        <tr className="addDueOptRow">
          <td>&nbsp;</td>
          <td colSpan="2">
            <div className="from-row">
              <div className="mb-0">
                <input
                  type="checkbox"
                  name="allPartialPayment"
                  checked={states.allPartialPayment === "1"}
                  id="all_Partial_Payment"
                  className="css-checkbox"
                  value="allPartialPayment"
                  onChange={updatePartialPayment}
                />
                <label htmlFor="all_Partial_Payment" className="css-checkbox-label">
                  {" "}
                  Allow Partial Payment(s) <i className="bi bi-question-circle-fill"></i>
                </label>
              </div>
              <div className="">
                <input
                  ref={breakMilestoneInput}
                  checked={states.breakMilestonePaymentPlan === "1"}
                  type="checkbox"
                  name="breakMilestonePaymentPlan"
                  id="break_milestone_payment_plan"
                  className="css-checkbox"
                  value="breakMilestonePaymentPlan"
                  onChange={breakToMilestonePayment}
                />
                <label htmlFor="break_milestone_payment_plan" className="css-checkbox-label">
                  {" "}
                  Break into Milestones or Payment Plan
                </label>
              </div>
            </div>

            {states.showCommitmentStatus && (
              <div className="from-row committedOrNotcommitted">
                <div className="mb-0">
                  <input
                    type="radio"
                    name="committedOrNot"
                    checked={states.committedOrNot === "notCommitted"}
                    id="notCommitted"
                    className="css-checkbox"
                    value="notCommitted"
                    onChange={updateCommittementStatus}
                  />
                  <label htmlFor="notCommitted" className="css-radio-label radGroup2">
                    Not Yet Committed
                  </label>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="committedOrNot"
                    checked={states.committedOrNot === "committed"}
                    id="committed"
                    className="css-checkbox"
                    value="committed"
                    onChange={updateCommittementStatus}
                  />
                  <label htmlFor="committed" className="css-radio-label radGroup2">
                    Committed
                  </label>
                </div>
              </div>
            )}
          </td>
        </tr>
      )}
      {states.breakMilestonePaymentPlan === "1" && (
        <tr className="paymentPlanOpt">
          <td>&nbsp;</td>
          <td colSpan="2">
            <div className="row row-cols-sm-auto g-1 align-items-center">
              <div className="col-12">
                <select
                  ref={breakMilestonePaymentDivisionSelect}
                  className="form-select"
                  name="paymentPlanOptions"
                  id="paymentPlanOptions"
                  onChange={updateDue}
                  value={states.paymentPlanOptions}
                >
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="24">24</option>
                  <option value="36">36</option>
                  <option value="48">48</option>
                </select>
              </div>
              <div className="col-12">payments of</div>
              <div className="col-12">
                <span className="paymentInstallation">${due.toFixed(2)}</span>
              </div>
            </div>
          </td>
        </tr>
      )}
      <tr className="dueNowRow">
        <td>&nbsp;</td>
        <td className="text-end yellow">
          <strong>Due Now:</strong>
          <a
            onClick={() => setShowDueOptions(!showDueOptions)}
            className="addDueOpt"
            role="button"
            tabIndex={0}
          >
            <i className="bi bi-three-dots"></i>
          </a>
        </td>
        <td className="text-end paymentInstallation yellow">${due.toFixed(2)}</td>
      </tr>
    </>
  )
}

export default DueOptions
