import React, { useState, useEffect } from "react"
import OptionItem from "./optionItem"

const InvoiceOption = ({ showAddOption, options, setOptions, total, setNextTotal }) => {
  const [showOptions, setShowOptions] = useState([
    { name: "payment", label: "Payment", isPositive: false, isVisible: false },
    { name: "discount", label: "Discount", isPositive: false, isVisible: false },
    { name: "shipping", label: "Shipping & Handling", isPositive: true, isVisible: false },
    { name: "installation", label: "Installation", isPositive: true, isVisible: false },
    { name: "account", label: "Account Credit", isPositive: false, isVisible: false },
  ])

  const [selectedOption, setSelectedOption] = useState("payment")

  const updateSelectedOption = e => {
    setSelectedOption(e.target.value)
  }

  const addOption = () => {
    setShowOptions(
      showOptions.map(option => (option.name === selectedOption ? { ...option, isVisible: true } : option))
    )
  }

  const deleteOption = optionToDelete => {
    setShowOptions(
      showOptions.map(option => (option.name === optionToDelete ? { ...option, isVisible: false } : option))
    )
    setOptions({ ...options, [optionToDelete]: 0 })
  }

  const updateOption = (option, e, isPositive = true) => {
    const updatedOptions = { ...options }
    let value = parseFloat(e.target.value)

    updatedOptions[option] = value
    setOptions(updatedOptions)
  }

  useEffect(() => {
    setShowOptions(
      showOptions.map(option => {
        return { ...option, isVisible: options[option.name].toFixed(2) !== "0.00" }
      })
    )

    setNextTotal(
      showOptions.reduce((hiddenTotal, option) => {
        return hiddenTotal + (option.isPositive ? options[option.name] : -options[option.name])
      }, total)
    )
  }, [options, total])

  useEffect(() => {
    setNextTotal(total)
  }, [])

  return (
    <>
      <OptionItem
        options={options}
        index={0}
        showOptions={showOptions}
        updateOption={updateOption}
        deleteOption={deleteOption}
        initialTotal={total}
      />

      {showAddOption && (
        <tr className="invoiceOptionsRow">
          <td className="text-end">Optional: Add</td>
          <td>
            <select
              className="form-select"
              name="invoice-optional-type"
              id="invoice-optional-type"
              onChange={updateSelectedOption}
            >
              <option value="payment">Previous Payment(s)</option>
              <option value="discount">Discount</option>
              <option value="shipping">Shipping &amp; Handling</option>
              <option value="installation">Installation</option>
              <option value="account">Account Credit</option>
            </select>
          </td>
          <td>
            <button className="btn btn-primary addPaymentOption" type="button" onClick={addOption}>
              <i className="bi bi-plus-lg"></i> Add Option
            </button>
          </td>
        </tr>
      )}
    </>
  )
}

export default InvoiceOption
