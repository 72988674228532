import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import AxiosInstance from "../../../../services/axiosInstance"
import { getSelectOptions } from "../../Item/helpers"
import DatePicker from "react-datepicker"

const AccountingModal = ({ showModal, setShowModal, states, setStates, accounting, setAccounting }) => {
  const [shows, setShows] = useState({
    contacts: false,
    tasks: false,
    agreements: false,
    campaigns: false,
    folders: false,
  })

  const handleClose = () => {
    setShowModal(false)
    setAccounting({
      metaJson: { contacts: [{}] },
      transactionStatus: "paid",
      transactionType: "purchase_expense",
    })
  }

  const updateField = (e, fieldName, value) => {
    value = value ?? e.target.value
    setAccounting({ ...accounting, [fieldName]: value })
  }

  const save = e => {
    saveAccounting()
  }

  const addLinkdedContact = e => {
    e.preventDefault()
    setAccounting({ ...accounting, metaJson: { contacts: [...(accounting.metaJson?.contacts || []), []] } })
  }

  const saveAccounting = async () => {
    if (!accounting.transactionDate || !accounting.amount) {
      return
    }

    const data =
      accounting.transactionDate instanceof Date
        ? { ...accounting, transactionDate: convertDatetimeFormat(accounting.transactionDate) }
        : accounting
    const { id, ...postData } = data
    let response
    try {
      if (id === undefined) {
        response = await AxiosInstance.post(`/accounting/`, postData)
      } else {
        response = await AxiosInstance.patch(`/accounting/${id}`, postData)
      }

      if (response.status === 200) {
        if (response.data.status) {
          const newAccounting = { ...accounting, id: response.data.id }
          const accountingType = accounting.transactionStatus === "paid" ? "paids" : "projected"
          let newList = []
          if (id === undefined) {
            newList = [...states.accountings[accountingType], newAccounting]
          } else {
            newList = states.accountings[accountingType].map(acc => {
              if (id === acc.id) {
                return newAccounting
              }
              return acc
            })
          }
          setStates({ ...states, accountings: { ...states.accountings, [accountingType]: newList } })
          handleClose()
        }
      } else {
        console.error("Unexpected status code:", response.status)
        return null
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
      return null
    }
  }

  const toggleShow = (e, type) => {
    e.preventDefault()
    setShows({ ...shows, [type]: !shows[type] })
  }

  const removeContact = (e, index) => {
    e.preventDefault()

    setAccounting({
      ...accounting,
      metaJson: { contacts: accounting.metaJson.contacts.filter((contact, i) => i !== index) },
    })
  }

  const updateContactData = (e, index, fieldName) => {
    e.preventDefault()

    setAccounting({
      ...accounting,
      metaJson: {
        contacts: accounting.metaJson.contacts.map((contact, i) => {
          if (i !== index) {
            return contact
          }
          return { ...contact, [fieldName]: e.target.value }
        }),
      },
    })
  }

  const convertDatetimeFormat = datetime => {
    const year = datetime.getFullYear()
    const month = datetime.getMonth() + 1
    const day = datetime.getDate()

    return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <h1 className="modal-title fs-5" id="manualRecordModalLabel">
          Add New Transaction
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 row align-items-center">
          <label htmlFor="titleId" className="col-sm-3">
            Title / Memo
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              id="titleId"
              name="title"
              placeholder=""
              className="form-control"
              value={accounting.title || ""}
              onChange={e => updateField(e, "title")}
            />
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="amount" className="col-sm-3">
            Amount
          </label>
          <div className="col-sm-9">
            <input
              id="amount"
              className="form-control"
              type="number"
              name="amount"
              value={accounting.amount || ""}
              onChange={e => updateField(e, "amount")}
            />
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="trans_date" className="col-sm-3">
            Date
          </label>
          <div className="col-sm-9">
            {/* <input type="text" name="trans_date"  data-toggle="datepicker"  value={accounting.transactionDate || ""} onChange={(e) => updateField(e, 'transactionDate')}/> */}
            <DatePicker
              id="trans_date"
              selected={accounting.transactionDate ? new Date(accounting.transactionDate) : new Date()}
              className="form-control"
              onChange={date => updateField(undefined, "transactionDate", date)}
            />
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="add_new_tags" className="col-sm-3">
            Tags
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              name="tags"
              id="add_new_tags"
              className="form-control"
              value={accounting.tags || ""}
              onChange={e => updateField(e, "tags")}
            />
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="typeStatus" className="col-sm-3">
            Type &amp; Status
          </label>
          <div className="col-sm-9">
            <div className="row row-cols-sm-auto g-3 align-items-center">
              <div className="col-12">
                <select
                  name="type"
                  id="typeStatus"
                  className="form-select"
                  value={accounting.transactionType || ""}
                  onChange={e => updateField(e, "transactionType")}
                >
                  <option value="purchase_expense">Purchase / Expense</option>
                  <option value="invoice_in">Invoice Receivable</option>
                  <option value="invoice_out">Invoice Payable</option>
                  <option value="estimate">Estimate</option>
                  <option value="proposal">Proposal</option>
                  <option value="quote">Quote</option>
                  <option value="purchase_order">Purchase Order</option>
                  <option value="credit_memo">Credit Memo</option>
                  <option value="receipt">Receipt</option>
                </select>
              </div>
              <div className="col-12">
                <select
                  name="status"
                  id="status"
                  className="form-select"
                  value={accounting.transactionStatus || ""}
                  onChange={e => updateField(e, "transactionStatus")}
                >
                  <option value="paid">Paid</option>
                  <option value="projected">Projected</option>
                  <option value="wish_list">Wish List</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="method" className="col-sm-3">
            Method / Account
          </label>
          <div className="col-sm-9">
            <select
              name="method"
              id="method"
              className="form-select"
              value={accounting.methodId || ""}
              onChange={e => updateField(e, "methodId")}
            >
              <option value="1">Cash</option>
              <option value="2">Account not connected to Swiftcloud</option>
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-sm-12">
            <a
              href="#"
              onClick={e => toggleShow(e, "contacts")}
              className="btnAddNewBlueDashed btnLinktoContact"
              data-bs-toggle="tooltip"
              title="Link to Contact"
            >
              <i className="bi bi-person-fill"></i>
            </a>
            <a
              href="#"
              onClick={e => toggleShow(e, "tasks")}
              className="btnAddNewBlueDashed btnLinktoTask"
              data-bs-toggle="tooltip"
              title="Link to Task / Project"
            >
              <i className="bi bi-check2"></i>
            </a>
            <a
              href="#"
              onClick={e => toggleShow(e, "agreements")}
              className="btnAddNewBlueDashed btnLinktoAgreement"
              data-bs-toggle="tooltip"
              title="Link to Billing Agreement"
            >
              <i className="bi bi-repeat"></i>
            </a>
            <a
              href="#"
              onClick={e => toggleShow(e, "campaigns")}
              className="btnAddNewBlueDashed btnLinktoMarketingExperiment"
              data-bs-toggle="tooltip"
              title="Link to Marketing / Campaign"
            >
              <i className="bi bi-bullseye"></i>
            </a>
            <a
              href="#"
              onClick={e => toggleShow(e, "folders")}
              className="btnAddNewBlueDashed btnLinktoFolder"
              data-bs-toggle="tooltip"
              title="Link to Folder / Workroom / Custom asset"
            >
              <i className="bi bi-database-fill"></i>
            </a>
          </div>
        </div>

        {shows.contacts && (
          <div className="mb-3 row linkToContactContainer">
            <div className="col-sm-12">
              {accounting.metaJson?.contacts?.map((contact, index) => {
                return (
                  <div className="row guestUserRow guestUserContainer" key={index}>
                    <div className="col-lg-12 BC_Multiple_User">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="bc-wrapper">
                            <input
                              id={`eventUserInfo${index}`}
                              className="form-control contact-user-name"
                              type="text"
                              name="eventUserInfo"
                              placeholder="Any email or Username"
                              autoComplete="off"
                              value={contact.user || ""}
                              onChange={e => updateContactData(e, index, "user")}
                            />
                            <div className="bc-menu list-group"></div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <select
                            name="eventUserRole"
                            id={`eventUserRole${index}`}
                            className="form-select contact-user-role"
                            value={contact.role || ""}
                            onChange={e => updateContactData(e, index, "role")}
                          >
                            <option>== ROLES ==</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {index !== 0 && (
                      <a
                        href="#"
                        onClick={e => removeContact(e, index)}
                        className="btnDeleteGuestUser"
                        data-bs-toggle="tooltip"
                        data-bs-title="Delete Guest User"
                      >
                        <i className="bi bi-x-lg"></i>
                      </a>
                    )}
                  </div>
                )
              })}
              <div className="row shareRow">
                <div className="col-lg-12 shareInfoPerson">
                  <button
                    type="button"
                    className="btn btnAddNewGreenDashed"
                    id="btnAddNewGuestUser"
                    data-bs-toggle="tooltip"
                    title="Add Guest User"
                    onClick={addLinkdedContact}
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {shows.tasks && (
          <div className="mb-3 row linkToTaskContainer align-items-center">
            <label htmlFor="task_list" className="col-sm-3">
              Task
            </label>
            <div className="col-sm-9">
              <select
                name="task_list"
                id="task_list"
                className="form-select"
                value={accounting.task || ""}
                onChange={e => updateField(e, "task")}
              >
                <option value="">== Task List here ==</option>
                {getSelectOptions(states, "tasks", "title")}
              </select>
            </div>
          </div>
        )}

        {shows.agreements && (
          <div
            className="mb-3 row linkToAgreementContainer align-items-center"
            value={accounting.agreement || ""}
            onChange={e => updateField(e, "agreement")}
          >
            <label htmlFor="agreement_list" className="col-sm-3">
              Agreement
            </label>
            <div className="col-sm-9">
              <select name="agreement_list" id="agreement_list" className="form-select">
                <option value="">== Agreement List here ==</option>
                {getSelectOptions(states, "agreements", "name")}
              </select>
            </div>
          </div>
        )}

        {shows.campaigns && (
          <div className="mb-3 row linktoMarketingExperiment align-items-center">
            <label htmlFor="marketing_list" className="col-sm-3">
              Marketing / Campaign
            </label>
            <div className="col-sm-9">
              <select
                name="marketing_list"
                id="marketing_list"
                className="form-select"
                value={accounting.campaign || ""}
                onChange={e => updateField(e, "campaign")}
              >
                <option value="">== Campaign List here ==</option>
                {getSelectOptions(states, "campaigns", "name")}
              </select>
            </div>
          </div>
        )}

        {shows.folders && (
          <div className="mb-3 row linktoFolders align-items-center">
            <label htmlFor="folder_list" className="col-sm-3">
              Folder / Workroom / Custom asset
            </label>
            <div className="col-sm-9">
              <select
                name="folder_list"
                id="folder_list"
                className="form-select"
                value={accounting.folder || ""}
                onChange={e => updateField(e, "folder")}
              >
                <option value="">== List of Folder ==</option>
                {getSelectOptions(states, "folders", "name")}
              </select>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
          <i className="bi bi-x-lg"></i> Close
        </button>
        <button type="button" className="btn btn-primary" onClick={save}>
          <i className="bi bi-check2"></i> Save & Add
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AccountingModal
