import React from "react"

export const convertDatetimeFormat = datetimeString => {
  const datetime = new Date(datetimeString)

  const year = datetime.getFullYear()
  const month = datetime.getMonth() + 1
  const day = datetime.getDate()

  return `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`
}
