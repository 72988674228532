import React, { useEffect, useState } from "react"

const OptionItem = ({ options, index, showOptions, updateOption, deleteOption, initialTotal }) => {
  const currentOption = showOptions[index]

  const { label, name, isPositive, isVisible } = currentOption

  const renderNext = () => {
    const nextTotal = initialTotal + (isPositive ? options[name] : -options[name])

    if (index + 1 >= showOptions.length) {
      return <></>
    }

    return (
      <OptionItem
        options={options}
        index={index + 1}
        showOptions={showOptions}
        updateOption={updateOption}
        deleteOption={deleteOption}
        initialTotal={nextTotal}
      />
    )
  }

  if (!isVisible) return renderNext()

  const handleUpdateOption = e => {
    updateOption(name, e, isPositive)
  }

  return (
    <>
      <tr className="paymentOptionRow">
        <td className="text-end">
          <button
            role="button"
            tabIndex={0}
            onClick={() => deleteOption(name)}
            className="btn btn-opt-trash btn-round btnRemovePaymentOption"
            data-bs-toggle="tooltip"
            title="Delete this row"
          >
            <i className="bi bi-trash-fill"></i>
          </button>
          {label}
        </td>
        <td>
          <input
            value={options[name]}
            onChange={e => handleUpdateOption(e)}
            className="form-control paymentOpt"
            type="number"
            data-optiontype={name}
            data-type="subtract"
            name={name}
            min="0"
          />
        </td>
        <td className="text-end paymentOptTotal">
          ${(initialTotal + (isPositive ? options[name] : -options[name])).toFixed(2)}
        </td>
      </tr>
      {renderNext()}
    </>
  )
}

export default OptionItem
