import React, { useEffect } from "react"
import DhAutoRow from "./dhAutoRow"
import InitialRow from "./initialRow"
import AxiosInstance from "../../../../services/axiosInstance"

const DhAuto = ({ states, setStates }) => {
  const addMoreDha = e => {
    if (e !== undefined) {
      e.preventDefault()
    }
    setStates({
      ...states,
      dhAuto: {
        ...states.dhAuto,
        automation_option: [...(states.dhAuto?.automation_option ?? []), { set_option: "emailMe" }],
      },
    })
  }

  useEffect(() => {
    if (states.dhAuto.automation_option?.length === 0) {
      addMoreDha()
    }
    getProjectTypeData()
  }, [states.itemId])

  const getProjectTypeData = async () => {
    try {
      const response = await AxiosInstance.get("/settings/transaction-type")
      if (response.status === 200) {
        setStates({ ...states, projectTypes: response.data.items })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when retrieving project types", error)
    }
  }

  const getAssetTypeData = async () => {
    try {
      const response = await AxiosInstance.get("/settings/asset-type")
      if (response.status === 200) {
        setStates({ ...states, assetTypes: response.data.items })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when retrieving asset types", error)
    }
  }

  const getDriveFolderList = async () => {
    try {
      const response = await AxiosInstance.get(`/drive/list`, {
        params: {
          type: "folder",
        },
      })
      if (response.status === 200) {
        setStates({ ...states, driveFolderList: response.data.items })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when driver folder list", error)
    }
  }

  const getDriveList = async () => {
    try {
      const response = await AxiosInstance.get(`/drive/list-sheet`)
      if (response.status === 200) {
        setStates({ ...states, driveList: response.data.items })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when driver list", error)
    }
  }

  const getPrimaryEmail = async () => {
    try {
      const response = await AxiosInstance.get("/settings/general-setting/settings-data")
      if (response.status === 200) {
        setStates({
          ...states,
          primaryEmail: response.data.data.filter(data => {
            if (data.type === "Primary") {
              return data
            }
          }),
        })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when fetching primary email", error)
    }
  }

  const getContactGroupList = async () => {
    try {
      const response = await AxiosInstance.get(`/contact/group`, {
        params: {
          page: 1,
        },
      })
      if (response.status === 200) {
        setStates({ ...states, contactGroupList: response.data.items })
      } else {
        console.log("Unexpected response status", response.status)
      }
    } catch (error) {
      console.error("Error when primary email", error)
    }
  }

  useEffect(() => {
    if (states.projectTypes) {
      getAssetTypeData()
    }
  }, [states.projectTypes])

  useEffect(() => {
    if (states.assetTypes) {
      getDriveFolderList()
    }
  }, [states.assetTypes])

  useEffect(() => {
    if (states.driveFolderList) {
      getPrimaryEmail()
    }
  }, [states.driveFolderList])

  useEffect(() => {
    if (states.primaryEmail) {
      getContactGroupList()
    }
  }, [states.primaryEmail])

  useEffect(() => {
    if (states.contactGroupList) {
      getDriveList()
    }
  }, [states.contactGroupList])

  return (
    <div className="col-lg-12 mb-3" id="for_you_panel">
      <div className="accordion" id="foryouPanelAccordion">
        <div className="accordion-item panel panel-blue">
          <div className="accordion-header">
            <div
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target="#for_you_container"
              aria-expanded="true"
              aria-controls="for_you_container"
            >
              <span className="panel-heading-icon">
                <i className="bi bi-gear-fill"></i>
              </span>
              <span className="panel-heading-title">FOR YOU: Data Handling &amp; Automation</span>
            </div>
          </div>
          <div
            id="for_you_container"
            className="accordion-collapse collapse show"
            aria-labelledby="for_you_container"
          >
            <div className="accordion-body">
              <h4 className="">{states.dhaIntroText}</h4>
              <div className="forYouOptionContainer">
                <InitialRow states={states} setStates={setStates} />

                {states.dhAuto.automation_option?.map((dha, index) => {
                  return (
                    <DhAutoRow index={index} key={index} data={dha} states={states} setStates={setStates} />
                  )
                })}

                <div className="text-center btnAddNewFYOptionContainer">
                  <button
                    className="btnAddNewGreenDashed btnAddNewFYOption"
                    onClick={addMoreDha}
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add More to Data &amp; Automations"
                    aria-label="Add More to Data &amp; Automations"
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DhAuto
