import React, { useEffect } from "react"
import { InvoiceTypes, InvoiceStatuses } from "./constants"

const TypeNStatus = ({ states, setStates }) => {
  const handleStatusChange = e => {
    setStates({ ...states, status: e.target.value })
  }

  const getDefaultStatusByType = type => {
    switch (type) {
      case InvoiceTypes.ESTIMATE:
      case InvoiceTypes.QUOTE:
        return InvoiceStatuses.NOT_YET_COMMITTED
      case InvoiceTypes.CREDIT_MEMO:
      case InvoiceTypes.RECEIPT:
        return InvoiceStatuses.PAID
      default:
        return InvoiceStatuses.DUE
    }
  }

  const handleTypeChange = e => {
    const selectedType = e.target.value
    setStates({
      ...states,
      type: selectedType,
      showCommitmentStatus: ![InvoiceTypes.INVOICE, InvoiceTypes.PURCHASE_ORDER].includes(selectedType),
      status: getDefaultStatusByType(selectedType),
    })
  }

  useEffect(() => {
    setStates({
      ...states,
      showCommitmentStatus: ![InvoiceTypes.INVOICE, InvoiceTypes.PURCHASE_ORDER].includes(states.type),
    })
  }, [states.type])

  return (
    <div className="panel-heading">
      <div className="row invoiceAddNewBtnContainer">
        <div className="col-sm-12">
          <div className="form-inline float-start me-2">
            <select
              className="form-select form-select-lg pull-left mb-2 mb-md-0"
              id="invoiceTypeOpts"
              value={states.type}
              onChange={handleTypeChange}
            >
              <option value={InvoiceTypes.INVOICE}>Invoice</option>
              <option value={InvoiceTypes.ESTIMATE}>Estimate</option>
              <option value={InvoiceTypes.QUOTE}>Quote</option>
              <option value={InvoiceTypes.PURCHASE_ORDER}>Purchase Order</option>
              <option value={InvoiceTypes.CREDIT_MEMO}>Credit Memo</option>
              <option value={InvoiceTypes.RECEIPT}>Receipt</option>
            </select>
          </div>
          <div className="form-inline float-end">
            <select
              className="form-select form-select-lg pull-right"
              id="invoice_status_opts"
              value={states.status}
              onChange={handleStatusChange}
            >
              <option value={InvoiceStatuses.DUE}>Due</option>
              <option value={InvoiceStatuses.PAID_PARTIAL}>Paid Partial</option>
              <option value={InvoiceStatuses.PAID}>PAID</option>
              <option value={InvoiceStatuses.CANCELED}>Canceled</option>
              <option value={InvoiceStatuses.REFUNDED}>Refunded</option>
              <option value={InvoiceStatuses.UNPAID_OVERDUE}>Unpaid Overdue</option>
              {states.showCommitmentStatus && (
                <>
                  <option value={InvoiceStatuses.NOT_YET_COMMITTED}>Not Yet Committed</option>
                  <option value={InvoiceStatuses.COMMITTED}>Committed</option>
                </>
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeNStatus
