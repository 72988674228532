import React, { useState } from "react"
import { Modal } from "react-bootstrap"

const CompletePayment = ({ showCompletePaymentModal, setShowCompletePaymentModal, due, payorName }) => {
  const handleClose = () => {
    setShowCompletePaymentModal(false)
  }
  return (
    <Modal show={showCompletePaymentModal} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="completePaymentNowModalLabel">
          Complete Payment Now
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <input type="checkbox" name="permission" id="permissionCheck" className="css-checkbox" />
          <label htmlFor="permissionCheck" className="css-checkbox-label mb-0">
            I have permission from {payorName} to bill ${due.toFixed(2)} using this method. A receipt will be
            generated and sent to the client.{" "}
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
          <i className="bi bi-x-lg"></i> Cancel
        </button>
        <button className="btn btn-primary">
          <i className="bi bi-check2"></i> Complete Payment Now
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CompletePayment
