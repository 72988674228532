import React from "react"
import { convertDatetimeFormat } from "./helpers"

const Projected = ({ states, setStates, clickDelete, editAccounting }) => {
  const doNothing = e => {
    e.preventDefault()
  }

  return (
    <div className="tab-pane fade" id="projected" role="tabpanel" aria-labelledby="projected-tab">
      <table
        id="projected_table"
        className="table table-striped customCheckbox table-head-color dataTable no-footer"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th width="5%">
              <a tabIndex="0" href="#" onClick={doNothing} className="projected_table_action table-head-icon">
                <i className="bi bi-gear-fill"></i>
              </a>
            </th>
            <th width="5%" className="d-none d-md-table-cell text-center">
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="10%" className="d-none d-md-table-cell text-center">
              Date{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="40%">
              Type / Title / Memo{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="15%" className="text-end">
              Amount{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="25%" className="d-none d-md-none d-lg-table-cell"></th>
          </tr>
          <tr className="display-none projected_table_action_container">
            <td colSpan="6" className="text-left">
              <div className="batch-action-container">
                <button className="btn btn-trash">
                  <i className="bi bi-trash-fill"></i> Move to Trash
                </button>
                <button
                  className="btn medium_purple"
                  data-bs-toggle="tooltip"
                  title="Merge Duplicate Records"
                >
                  <i className="bi bi-share-fill flip-horizontal"></i> Merge
                </button>
                <button
                  className="btn btn-success"
                  data-bs-toggle="tooltip"
                  title="Nest related records into a single row to keep things organized."
                >
                  <i className="bi bi-bezier"></i> Nest
                </button>
                <button className="btn btn-default" data-bs-toggle="tooltip" title="Un-Nest">
                  <i className="bi bi-bezier2"></i> Un-Nest
                </button>
                <button className="btn btn-secondary" data-bs-toggle="tooltip" title="Tags">
                  <i className="bi bi-tags-fill"></i> Tags
                </button>
              </div>
            </td>
          </tr>
          <tr className="display-none projected_table_action_container filter_table_action_container">
            <td colSpan="6" className="text-left">
              <div className="batch-action-container">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="form-inline mb-3">
                      <label className="mb-0 mr-2">By Type</label>
                      <select className="form-select mb-0">
                        <option value="Image">Image</option>
                        <option value="Audio">Audio</option>
                        <option value="Video">Video</option>
                        <option value="Archive">Archive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="form-inline mb-2">
                      <h4>
                        <i className="bi bi-funnel-fill"></i> Show me
                        <select className="form-select mb-0 mx-2">
                          <option value="Contacts">Contacts</option>
                          <option value="Files_Workrooms">Files & Workrooms</option>
                          <option value="Accounting_Records">Accounting Records</option>
                        </select>
                        that are…
                      </h4>
                    </div>
                    <div className="mb-3">
                      <select className="form-select mb-3">
                        <option value="Tagged_with…">Tagged with…</option>
                        <option value="Selling_Opportunities">Selling Opportunities</option>
                      </select>
                      <button
                        type="button"
                        className="btnAddNew"
                        id="btnAddNew"
                        data-bs-toggle="tooltip"
                        data-placement="bottom"
                        title="Add New"
                      ></button>
                    </div>
                    <div className="mb-3">
                      <label>& Add a Custom Column</label>
                      <input
                        type="text"
                        name="addCustomColumn"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label>& then sort the records by…</label>
                      <input
                        type="text"
                        name="sortRecordsBy"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                    <h4>
                      <i className="bi bi-bullseye"></i> Saved Filtered Views
                    </h4>
                    <ul>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Helpdesk Tasks
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Homebuyer Selling Opps
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Open Client Projects
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          UserSavedFilterLabelHere
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {states.accountings?.projected?.map(projected => {
            return (
              <tr className="neutral_row" key={projected.id}>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id={`P_ID12_${projected.id}`}
                      className="css-checkbox"
                    />
                    <label htmlFor={`P_ID12_${projected.id}`} className="css-checkbox-label"></label>
                    <span className="tableColChkSpan account_status bg_green">
                      <i className="bi bi-box-arrow-right"></i>
                    </span>
                  </div>
                </td>
                <td className="text-center d-none d-md-table-cell">
                  <span className="btn btn-status" data-bs-toggle="tooltip" title="Projected">
                    <i className="bi bi-clock"></i>
                  </span>
                </td>
                <td className="text-center d-none d-md-table-cell">
                  {convertDatetimeFormat(projected.transactionDate)}
                </td>
                <td className="col_to_from">{projected.title}</td>
                <td className="text-end">
                  <button
                    className="btn btn-pay"
                    data-bs-toggle="tooltip"
                    title="Click to Pay Now"
                    onClick={doNothing}
                  >
                    <strong>
                      <i className="bi bi-box-arrow-right"></i> {projected.amount}
                    </strong>
                  </button>
                </td>
                <td className="text-end colAction d-none d-lg-block">
                  <a
                    href="#"
                    className="btn btn-colAction btn-chat"
                    data-bs-toggle="tooltip"
                    title="Comments"
                    onClick={doNothing}
                  >
                    <i className="bi bi-chat-fill"></i>
                    <span className="badge badge-danger">0</span>
                  </a>{" "}
                  <a
                    href="#"
                    className="btn btn-secondary btn-colAction btn-users"
                    data-bs-toggle="tooltip"
                    title="Attachments"
                    onClick={doNothing}
                  >
                    <i className="bi bi-paperclip"></i>
                    <span className="badge badge-danger">0</span>
                  </a>{" "}
                  <a
                    href="#"
                    className="btn btn-trash btn-colAction"
                    data-bs-toggle="tooltip"
                    title="Delete"
                    onClick={e => clickDelete(e, projected, "projected")}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </a>
                </td>
              </tr>
            )
          })}

          {/* <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID1" className="css-checkbox" />
                        <label htmlFor="P_ID1" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_blue">
                            <i className="bi bi-arrow-clockwise"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-paypal"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">02/13/2017</td>
                    <td className="col_to_from">
                        Subscription Payment From{" "}
                        <a href="#" className="user_search" data-val="lidia">
                        Lidia
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for SSL
                    </td>
                    <td className="text-end">194.30</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a
                        href="#"
                        className="btn btn-colAction btn-chat"
                        data-bs-toggle="tooltip"
                        title="Comments"
                        >
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-colAction btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID2" className="css-checkbox" />
                        <label htmlFor="P_ID2" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_green">
                            <i className="bi bi-box-arrow-in-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-file-earmark-text"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">01/20/2017</td>
                    <td className="col_to_from">
                        Invoice To{" "}
                        <a href="#" className="user_search" data-val="Aishwarya Rai">
                        Aishwarya Rai
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for Hosting through...{" "}
                        <div className="colTags">
                        <a href="#" data-val="hosting">
                            #hosting
                        </a>
                        </div>
                    </td>
                    <td className="text-end">291.30</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a
                        href="#"
                        className="btn btn-colAction btn-chat"
                        data-bs-toggle="tooltip"
                        title="Comments"
                        >
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID3" className="css-checkbox" />
                        <label htmlFor="P_ID3" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_red">
                            <i className="bi bi-box-arrow-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span
                        className="btn btn-status"
                        data-bs-toggle="tooltip"
                        title="Chase Business"
                        >
                        <i className="bi bi-bank2"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">06/02/2016</td>
                    <td>
                        Payment To Bank Wire{" "}
                        <div className="colTags">
                        <a href="#" data-val="06/02/2016">
                            #06/02/2016
                        </a>
                        </div>
                    </td>
                    <td className="text-end">(-123.00)</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a href="#" className="btn btn-chat" data-bs-toggle="tooltip" title="Comments">
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-colAction btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID4" className="css-checkbox" />
                        <label htmlFor="P_ID4" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_red">
                            <i className="bi bi-box-arrow-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-paypal"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">06/24/2015</td>
                    <td>Payment To Tonys lidia showbiz ded</td>
                    <td className="text-end">(-39.21)</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a
                        href="#"
                        className="btn btn-colAction btn-chat"
                        data-bs-toggle="tooltip"
                        title="Comments"
                        >
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-colAction btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID5" className="css-checkbox" />
                        <label htmlFor="P_ID5" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_blue">
                            <i className="bi bi-arrow-clockwise"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="Cash">
                        <i className="bi bi-currency-dollar"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">04/27/2015</td>
                    <td className="col_to_from">
                        Subscription Payment To{" "}
                        <a href="#" className="user_search" data-val="CheapSSL.com">
                        CheapSSL.com
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for Test - running balance
                    </td>
                    <td className="text-end">(-10.50)</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a
                        href="#"
                        className="btn btn-chat btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Comments"
                        >
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-users btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">0</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID6" className="css-checkbox" />
                        <label htmlFor="P_ID6" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_red">
                            <i className="bi bi-box-arrow-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-paypal"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">01/31/2017</td>
                    <td className="col_to_from">
                        Payment To{" "}
                        <a href="#" className="user_search" data-val="Tejas">
                        Tejas
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for Test
                    </td>
                    <td className="text-end">(-100.00)</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a href="#" className="btn btn-chat" data-bs-toggle="tooltip" title="Comments">
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID7" className="css-checkbox" />
                        <label htmlFor="P_ID7" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_green">
                            <i className="bi bi-box-arrow-in-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-file-earmark-text"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">05/27/2017</td>
                    <td className="col_to_from">
                        Invoice To{" "}
                        <a href="#" className="user_search" data-val="Tejas">
                        Tejas
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for Test
                    </td>
                    <td className="text-end">100.00</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a href="#" className="btn btn-chat" data-bs-toggle="tooltip" title="Comments">
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr>
                <tr className="neutral_row">
                    <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                        <input type="checkbox" name="fileid[]" id="P_ID8" className="css-checkbox" />
                        <label htmlFor="P_ID8" className="css-checkbox-label"></label>
                        <span className="tableColChkSpan account_status bg_red">
                            <i className="bi bi-box-arrow-right"></i>
                        </span>
                        </div>
                    </td>
                    <td className="text-center d-none d-md-table-cell">
                        <span className="btn btn-status" data-bs-toggle="tooltip" title="PayPal Main">
                        <i className="bi bi-paypal"></i>
                        </span>
                    </td>
                    <td className="text-center d-none d-md-table-cell">04/08/2017</td>
                    <td className="col_to_from">
                        Payment To{" "}
                        <a href="#" className="user_search" data-val="Samier Sompura">
                        Samier Sompura
                        </a>{" "}
                        <a
                        href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                        className="user_link"
                        target="_blank"
                        >
                        <i className="bi bi-person-fill"></i>
                        </a>{" "}
                        for Test
                    </td>
                    <td className="text-end">(-100.00)</td>
                    <td className="text-end colAction d-none d-lg-block">
                        <a href="#" className="btn btn-chat" data-bs-toggle="tooltip" title="Comments">
                        <i className="bi bi-chat-fill"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-secondary btn-users"
                        data-bs-toggle="tooltip"
                        title="Attachments"
                        >
                        <i className="bi bi-paperclip"></i>
                        <span className="badge badge-danger">1</span>
                        </a>
                        <a
                        href="#"
                        className="btn btn-trash btn-colAction"
                        data-bs-toggle="tooltip"
                        title="Delete"
                        >
                        <i className="bi bi-trash-fill"></i>
                        </a>
                    </td>
                </tr> */}
        </tbody>
      </table>
    </div>
  )
}

export default Projected
