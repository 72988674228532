import React, { useEffect } from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"

const InitialRow = ({ states, setStates }) => {
  const updateData = (e, fieldName) => {
    setStates({
      ...states,
      dhAuto: {
        ...states.dhAuto,
        [fieldName]: e.target.value,
      },
    })
  }

  return (
    <div className="blueOutlineBox">
      <div className="row row-cols-lg-auto g-2 align-items-center">
        <div className="col-12">Save signed / completed PDFs into...</div>
        <div className="col-12">
          <Form.Select
            name="initial_automation_option"
            defaultValue={states.dhAuto.initialRow?.initialAutomationOption}
            key={states.dhAuto.initialRow?.initialAutomationOption}
            className="form-select invisibleSelect"
          >
            <option value="swiftcloud_drive">My SwiftCloud Drive</option>
          </Form.Select>
        </div>
        <div className="col-12"> {">>"} </div>
        <div className="col-12">
          <Form.Select
            name="initial_incoming_opt"
            value={states.dhAuto?.initial_incoming_opt}
            key={states.dhAuto?.initial_incoming_opt}
            className="form-select incomingOpt invisibleSelect"
            onChange={e => updateData(e, "initial_incoming_opt")}
          >
            <option value="Incoming">Incoming</option>
            <option value="TaskJobSalesOpportunity">Task / Job / Sales Opportunity</option>
            <option value="Root">Root</option>
            <option value="Folder">Folder</option>
          </Form.Select>
        </div>
        {states.dhAuto?.initial_incoming_opt === "Folder" && (
          <>
            <div className="col-12 incomingFolderList"> {">>"} </div>
            <div className="col-12 incomingFolderList">
              <Form.Select
                name="initial_folder"
                value={states.dhAuto?.initial_folder}
                key={states.dhAuto?.initial_folder}
                className="form-select"
                onChange={e => updateData(e, "initial_folder")}
              >
                <option value={""}>== List of Folders ==</option>
                {states.driveFolderList?.map((folder, index) => {
                  return (
                    <option value={folder.id} key={index}>
                      {folder.nameDisplay}
                    </option>
                  )
                })}
              </Form.Select>
            </div>
          </>
        )}

        <div className="col-12">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Your root-level folders will show here.</Tooltip>}
          >
            <span>
              <i className="bi bi-question-circle-fill" />
            </span>
          </OverlayTrigger>{" "}
          as a
        </div>
        <div className="col-12">
          <Form.Select
            name="initial_as_a"
            value={states.dhAuto?.initial_as_a}
            key={states.dhAuto?.initial_as_a}
            className="form-select invisibleSelect"
            onChange={e => {
              updateData(e, "initial_as_a")
            }}
          >
            <option value="workroom">Workroom (Default)</option>
            <option disabled="disabled">== TASK / JOB / OPPORTUNITY ==</option>
            {states.projectTypes?.map((projectType, index) => {
              return (
                <option value={`job_type_${projectType.id}`} key={index}>
                  {projectType.dealTypeLabel}
                </option>
              )
            })}
            <option disabled="disabled">== CUSTOM ASSET ==</option>
            {states.assetTypes?.map((assetType, index) => {
              return (
                <option value={`asset_type_${assetType.id}`} key={index}>
                  {assetType.name}
                </option>
              )
            })}
          </Form.Select>
        </div>
      </div>
    </div>
  )
}

export default InitialRow
