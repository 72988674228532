import React from "react"
import { convertDatetimeFormat } from "./helpers"

const History = ({ states, setStates, clickDelete, editAccounting }) => {
  const accountingType = "paids"
  const doNothing = e => {
    e.preventDefault()
  }

  return (
    <div className="tab-pane fade show active" id="history" role="tabpanel" aria-labelledby="history-tab">
      <table id="tblAccountHistory" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th width="5%">
              <a tabIndex="0" href="#" className="history_table_action table-head-icon">
                <i className="bi bi-gear-fill"></i>
              </a>
            </th>
            <th width="5%" className="d-none d-md-table-cell">
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="10%" className="d-none d-md-table-cell text-center">
              Date{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="40%">
              Type / Title / Memo{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="10%" className="text-end">
              Amount{" "}
              <a
                href="#"
                className="table_filter"
                onClick={doNothing}
                data-bs-toggle="tooltip"
                title="Custom Column View"
              >
                <i className="bi bi-plus-lg-square"></i>
              </a>
            </th>
            <th width="10%" className="d-none d-md-none display-none balance-col">
              Balance
            </th>
            <th width="20%" className="d-none d-md-none d-lg-table-cell"></th>
          </tr>
          <tr className="display-none history_table_action_container">
            <td colSpan="7" className="text-left">
              <div className="batch-action-container">
                <button className="btn btn-trash">
                  <i className="bi bi-trash-fill"></i> Move to Trash
                </button>
                <button
                  className="btn medium_purple"
                  data-bs-toggle="tooltip"
                  title="Merge Duplicate Records"
                >
                  <i className="bi bi-share-fill flip-horizontal"></i> Merge
                </button>
                <button
                  className="btn btn-success"
                  data-bs-toggle="tooltip"
                  title="Nest related records into a single row to keep things organized."
                >
                  <i className="bi bi-bezier"></i> Nest
                </button>
                <button className="btn btn-default" data-bs-toggle="tooltip" title="Un-Nest">
                  <i className="bi bi-bezier2"></i> Un-Nest
                </button>
                <button className="btn btn-secondary" data-bs-toggle="tooltip" title="Tags">
                  <i className="bi bi-tags-fill"></i> Tags
                </button>
              </div>
            </td>
          </tr>
          <tr className="display-none history_table_action_container history_filter_table_action_container">
            <td colSpan="7" className="text-left">
              <div className="batch-action-container">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="form-inline mb-3">
                      <label className="mb-0 mr-2">By Type</label>
                      <select className="form-select mb-0">
                        <option value="Image">Image</option>
                        <option value="Audio">Audio</option>
                        <option value="Video">Video</option>
                        <option value="Archive">Archive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="form-inline mb-2">
                      <h4>
                        <i className="bi bi-funnel-fill"></i> Show me
                        <select className="form-select mb-0 mx-2">
                          <option value="Contacts">Contacts</option>
                          <option value="Files_Workrooms">Files & Workrooms</option>
                          <option value="Accounting_Records">Accounting Records</option>
                        </select>
                        that are…
                      </h4>
                    </div>
                    <div className="mb-3">
                      <select className="form-select mb-3">
                        <option value="Tagged_with…">Tagged with…</option>
                        <option value="Selling_Opportunities">Selling Opportunities</option>
                      </select>
                      <button
                        type="button"
                        className="btnAddNew"
                        id="btnAddNew"
                        data-bs-toggle="tooltip"
                        title="Add New"
                      ></button>
                    </div>
                    <div className="mb-3">
                      <label>& Add a Custom Column</label>
                      <input
                        type="text"
                        name="addCustomColumn"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label>& then sort the records by…</label>
                      <input
                        type="text"
                        name="sortRecordsBy"
                        placeholder="tag-score"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                    <h4>
                      <i className="bi bi-bullseye"></i> Saved Filtered Views
                    </h4>
                    <ul>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Helpdesk Tasks
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Homebuyer Selling Opps
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          Open Client Projects
                        </a>
                      </li>
                      <li>
                        <a href="LINKTOTHIS_SAVED_CUSTOM_VIEW" className="btn btn-default">
                          UserSavedFilterLabelHere
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {states.accountings?.paids?.map(history => {
            return (
              <tr className="neutral_row" key={history.id}>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id={`bulkAction_${history.id}`}
                      className="css-checkbox"
                    />
                    <label htmlFor={`bulkAction_${history.id}`} className="css-checkbox-label"></label>
                    <span className="tableColChkSpan account_status bg_green">
                      <i className="bi bi-box-arrow-in-right"></i>
                    </span>
                  </div>
                </td>
                <td className="text-center d-none d-md-table-cell">
                  <span className="btn btn-status" data-bs-toggle="tooltip" title={history.transactionStatus}>
                    <i className="bi bi-clock"></i>
                  </span>
                </td>
                <td className="text-center d-none d-md-table-cell">
                  {convertDatetimeFormat(history.transactionDate)}
                </td>
                <td className="col_to_from">{history.title}</td>
                <td className="text-end initialInvoiceAmt"> {history.amount}</td>
                <td className="text-center d-none d-md-none display-none balance-col balance-color-red"></td>
                <td className="text-end colAction d-none d-md-none d-lg-table-cell">
                  <a
                    href="#"
                    className="btn btn-colAction btn-chat"
                    data-bs-toggle="tooltip"
                    title="Comments"
                  >
                    <i className="bi bi-chat-fill"></i>
                    <span className="badge badge-danger">0</span>
                  </a>{" "}
                  <a
                    href="#"
                    className="btn btn-secondary btn-colAction btn-users"
                    data-bs-toggle="tooltip"
                    title="Attachments"
                  >
                    <i className="bi bi-paperclip"></i>
                    <span className="badge badge-danger">0</span>
                  </a>{" "}
                  <a
                    href="#"
                    className="btn btn-trash btn-colAction"
                    data-bs-toggle="tooltip"
                    title="Delete"
                    onClick={e => clickDelete(e, history, accountingType)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </a>
                </td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr className="accouting_total_col">
            <th colSpan="4" style={{ textAlign: "right" }}>
              Total:
            </th>
            <th colSpan="3" className="d-none d-md-block d-lg-table-cell">
              &nbsp;
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default History
