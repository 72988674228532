import React, { useState, useEffect, useRef } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { Modal } from "react-bootstrap"

const AddSku = ({ items, setItems, showSkuModal, setShowSkuModal }) => {
  const selectSkuRef = useRef(null)
  const handleClose = () => setShowSkuModal(false)

  const [skus, setSkus] = useState([])
  const [selectedSku, setSelectedSku] = useState({})

  useEffect(() => {
    loadSku()
  }, [])

  const loadSku = async () => {
    setSkus([])
    try {
      const skuListResponse = await AxiosInstance.get(`/drive/item/`)
      if (skuListResponse.status === 200) {
        let skusTemp = skuListResponse.data.items
        setSkus(skusTemp)
        setSelectedSku(skusTemp[0])
      } else {
        console.error("Unexpected status code:", skuListResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  const openDrivePage = () => {
    window.open("/drive/", "_blank")
    setShowSkuModal(false)
  }

  const selectSku = e => {
    e.target.value === "add-new" ? openDrivePage() : setSelectedSku(skus[e.target.value])
  }

  const insertSku = () => {
    if (selectSkuRef.current.value === "add-new") {
      openDrivePage()
      return false
    }

    const productDetails = JSON.parse(selectedSku.productDetails)
    const index = items.reduce((index, item) => (item.index + 1 ? item.index > index : index), 0)
    const newItem = {
      label: selectedSku.itemName,
      itemLink: `/drive/item/${selectedSku.drive.fileURL}`,
      recurring: false,
      index: index,
      quantity: 1,
      price: parseFloat(productDetails.singlePrice),
      fileURL: selectedSku.drive.fileURL,
    }
    setItems([...items, newItem])
    setShowSkuModal(false)
  }

  return (
    <Modal show={showSkuModal} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="addToCartModalLabel">
          <i className="bi bi-cart-fill"></i> Items You Sell
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <div className="row align-items-center">
            <div className="col-sm-4">
              <label htmlFor="ecommProductList" className="my-0">
                Select Product
              </label>
            </div>
            <div className="col-sm-8">
              <select
                ref={selectSkuRef}
                className="form-select"
                id="ecommProductList"
                name="ecommProductList"
                onChange={selectSku}
              >
                {skus.map((sku, index) => {
                  const skuAdded = items.filter(item => item.fileURL === sku.drive.fileURL)
                  if (skuAdded.length === 0)
                    return (
                      <option value={index} key={index}>
                        {sku.itemName}
                      </option>
                    )

                  return null
                })}
                <option value="add-new">== ADD NEW ==</option>
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={handleClose}>
          <i className="bi bi-x-lg"></i> Cancel
        </button>
        <button type="button" className="btn btn-primary btnInsertProd" onClick={insertSku}>
          <i className="bi bi-box-arrow-in-right"></i> Insert Product
        </button>
        <input type="hidden" name="hidden_row_id" id="hidden_row_id" />
      </Modal.Footer>
    </Modal>
  )
}

export default AddSku
